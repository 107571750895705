<template>
    <div class="clinic_list">
        <van-grid :gutter="10" :column-num="2">
            <van-grid-item @click="changeClinic(index, $event)" v-for="(item, index) in newClinicList"
                           :key="item.clinicid" :account="item.clinicid"
                           :class="item.clinicid == newClinicId ? 'active' : ''" icon="wap-home" :text="item.clinicname" />
        </van-grid>
         <div class="ctrl_bottom" v-if="show">
             <button @click="doSubmit" class="submit_btn">提交</button>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters([
                'cliniclist','clinicid','clinicname'
            ]),
        },
        data() {
            return {
                show: true,
                newClinicId: '',
                newClinicName: '',
                newClinicList:[]
            }
        },
        mounted () {
            this.newClinicId = this.clinicid
            this.newClinicName = this.clinicname
            this.newClinicList = JSON.parse(this.cliniclist)
            console.log(this.newClinicList, '----cliniclist')
            console.log(this.newClinicList.length, '----size')
            this.$store.dispatch('hideOrShowNav', false)
        },
        methods: {
            pushHistory() {
                let state = {
                    title: '选择诊所',
                    url: document.URL
                }
                window.history.pushState(state, state.title, state.url)
            },
            changeClinic(index, event) {
                this.newClinicId = event.currentTarget.parentElement.getAttribute("account");
                this.newClinicName = event.currentTarget.parentElement.innerText;
            },
            doSubmit() {
                if(this.newClinicId == null || this.newClinicId == '') {
                    this.$toast('请选择诊所！')
                    return
                }
                this.show = false
                this.$store.dispatch('changeClinic', this.newClinicId)
                this.$store.dispatch('changeClinicName', this.newClinicName)
                this.$store.dispatch('changeIsRefresh', '1')
                this.$router.replace('/')
                // this.$router.go(-1)
            }
        },
    }
</script>

<style lang="scss" scoped>
    /deep/.van-grid-item .van-grid-item__content {
        padding: 0rem;
        background-color: #E7EBF7;
        margin: 7px 10px;
        font-size: 14px;
        color: #666666;
    }
    .van-cell {
        background-color: #F8F7FC;
        border-radius: 12px;
    }
</style>